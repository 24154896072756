import AdminAxiosInstance from "../../../services/adminAxiosInstance"

export const onGrabData = (currentPage, pages_count) => {
  return new Promise(async resolve => {
    let data = {
      items: [],
      pages_count: pages_count,
    }

    if (pages_count === null || currentPage <= pages_count) {
      data = await AdminAxiosInstance.get(`/meaus/list`, {
        params: {
          page: currentPage,
        },
      }).then(response => {
        return response.data
      })
    }
    resolve(data, pages_count)
  })
}
