import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import DetailChatBoxPopup from "../Detail/detailChatBoxPopup"
import TimeAgo from "timeago-react"
import { timeFormatter } from "../../../services/timeHelper"

const MeausList = ({ data }) => {
  const [selectFile, setSelectFile] = useState(false),
    [chatBox, setChatBox] = useState(false)

  return (
    <div className="table-responsive">
      <table id="UsersList" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th className="ps-4" width="6%">
              <a href="" className="btnTableAction">
                <i className="bi bi-gear-fill"></i>
              </a>
            </th>
            <th className="text-center" width="8%">
              ID
            </th>
            <th width="63%">User</th>
            <th className="text-center d-none d-md-table-cell" width="8%">
              FormSubmits
            </th>
            <th className="d-md-table-cell" width="8%">
              Login
            </th>
            <th width="7%" className="text-end d-md-table-cell"></th>
          </tr>
          {selectFile === true && (
            <tr className="table_action_container">
              <td colspan="8" className="text-left">
                <div className="batch-action-container">
                  <button className="btn btn-trash">
                    <i className="bi bi-trash-fill"></i> Move to Trash
                  </button>{" "}
                  <button className="btn btn-secondary">
                    <i className="bi bi-folder-fill"></i> Move To Folder
                  </button>
                </div>
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((meaus, index) => {
              const user = meaus.user
              return (
                <tr key={index}>
                  <td className="text-center tableColChkDel" key={index}>
                    <div className="tableColChkDelBox">
                      <input
                        type="checkbox"
                        name="fileid[]"
                        id="C_ID1"
                        className="css-checkbox"
                        onChange={e => setSelectFile(e.target.checked)}
                      />
                      <label htmlFor="C_ID1" className="css-checkbox-label"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    {user.id}
                    <br />
                  </td>
                  <td>
                    <a href="user_details.html">
                      <i className="bi bi-person-fill"></i> {user.username}
                    </a>{" "}
                    <a
                      href="javascript:;"
                      class="btnOpenChatDockEmail ms-2"
                      data-type="Email"
                      data-value={user.email}
                    >
                      <i class="bi bi-envelope-fill"></i> {user.email}
                    </a>
                  </td>
                  <td className="text-center d-none d-md-table-cell">{meaus.totalValueCount}</td>
                  <td className="d-none d-md-table-cell">
                    {user.lastLoggedIn === null ? (
                      ""
                    ) : (
                      <TimeAgo datetime={timeFormatter(user.lastLoggedIn)} />
                    )}
                  </td>
                  <td className="text-end tableColAction d-none d-md-table-cell">
                    <OverlayTrigger placement="left" overlay={<Tooltip>Login</Tooltip>}>
                      <a
                        href={`/login?switch_user=${user.email}`}
                        className="btn btn-sm btn-primary"
                        target="_blank"
                      >
                        <i className="bi bi-box-arrow-in-right" />
                      </a>
                    </OverlayTrigger>{" "}
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Hide / Do Not Count / Suppress / Internal</Tooltip>}
                    >
                      <a href="#" className="btn btn-delete">
                        <i className="bi bi-eye-slash-fill" />
                      </a>
                    </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {chatBox === true && <DetailChatBoxPopup setChatBox={setChatBox} />}
    </div>
  )
}

export default MeausList
