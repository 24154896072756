import React, { useEffect, useRef, useState } from "react"
import "../../../css/admin_style.css"
import AdminLayout from "../../SuperAdminLayout/adminLayout"
import { onGrabData } from "./meausListHelper"
import clsx from "clsx"
import MeausList from "./meausList"
import MeausLazyLoad from "./meausLazyLoad"
import { Link } from "gatsby"

const MeausPage = () => {
  const triggerRef = useRef(null)
  const { data, loading } = MeausLazyLoad({ triggerRef, onGrabData })

  const totalCount = data.reduce((acc, meau) => {
    return acc + meau.totalValueCount
  }, 0)

  return (
    <AdminLayout>
      <section className="my-4">
        <div className="container-fluid">
          <div class="row">
            <div class="col-lg-6 mx-auto">
              <div class="row row-cols-sm-auto g-2 align-items-center justify-content-center mb-3">
                <div class="col-12 fs-4">WEAUs: {totalCount}</div>
                <div class="col-12">
                  <Link to="/superAdmin0rama/users" class="btn btn-default">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <MeausList data={data} />
            </div>
          </div>
          <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
        </div>
      </section>
    </AdminLayout>
  )
}

export default MeausPage
