import * as React from "react"
import Seo from "../../components/seo"
import ReduxWrapper from "../../redux/reduxWrapper"
import MeausPage from "../../components/SuperAdmin/Meaus/meausPage"
import AdminPrivateRoute from "./adminPrivateRoute"

const IndexPage = props => {
  return (
    <React.Fragment>
      <Seo title="Weaus" />
      <AdminPrivateRoute component={MeausPage} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
